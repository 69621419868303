import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2560.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">


<path d="M12465 12140 c-211 -19 -400 -44 -450 -61 -16 -5 -59 -12 -95 -15
-36 -3 -85 -12 -110 -19 -25 -7 -72 -19 -105 -25 -113 -22 -244 -57 -260 -70
-5 -4 -29 -10 -52 -14 -24 -3 -57 -12 -75 -20 -18 -8 -67 -24 -108 -36 -41
-13 -79 -27 -85 -31 -5 -5 -18 -9 -30 -9 -12 0 -25 -4 -30 -9 -6 -5 -28 -14
-50 -20 -22 -7 -49 -16 -60 -21 -11 -5 -76 -32 -145 -61 -142 -60 -490 -231
-535 -263 -16 -12 -61 -37 -98 -56 -38 -19 -76 -42 -85 -50 -9 -9 -33 -25 -52
-36 -19 -10 -41 -25 -48 -31 -7 -7 -19 -13 -27 -13 -7 0 -15 -3 -17 -7 -1 -5
-25 -23 -53 -42 -27 -19 -82 -58 -122 -88 -39 -29 -76 -53 -82 -53 -6 0 -11
-4 -11 -8 0 -5 -18 -21 -40 -38 -22 -16 -51 -38 -64 -49 -12 -11 -40 -33 -62
-50 -230 -179 -589 -537 -784 -784 -36 -45 -79 -99 -96 -119 -29 -34 -106
-142 -129 -180 -5 -10 -12 -19 -15 -22 -15 -15 -70 -90 -70 -95 0 -4 -23 -41
-52 -83 -48 -72 -141 -228 -176 -297 -9 -16 -18 -32 -21 -35 -14 -12 -196
-391 -228 -475 -9 -22 -26 -64 -40 -93 -22 -49 -56 -139 -90 -242 -8 -25 -23
-66 -34 -92 -10 -26 -19 -55 -19 -65 0 -11 -4 -23 -8 -28 -5 -6 -19 -50 -31
-100 -13 -49 -27 -99 -31 -110 -5 -11 -18 -63 -30 -115 -12 -52 -28 -124 -37
-160 -23 -98 -32 -145 -48 -260 -7 -58 -16 -112 -19 -120 -3 -8 -10 -69 -16
-135 -6 -66 -15 -151 -20 -190 -12 -93 -12 -760 1 -855 5 -41 14 -124 19 -185
5 -60 14 -132 20 -160 5 -27 12 -72 15 -100 6 -46 29 -169 53 -275 6 -25 18
-77 27 -115 8 -39 18 -84 21 -100 3 -16 7 -33 10 -37 3 -5 19 -57 36 -116 16
-59 36 -128 43 -153 7 -26 17 -55 23 -65 15 -31 52 -148 52 -166 0 -9 3 -18 8
-20 4 -1 17 -30 30 -63 27 -70 56 -140 88 -210 12 -27 31 -72 43 -100 12 -27
26 -57 31 -65 6 -8 14 -24 19 -35 22 -49 86 -175 117 -230 18 -33 37 -67 40
-75 10 -22 163 -267 201 -322 18 -26 33 -50 33 -53 0 -3 22 -36 50 -73 27 -37
68 -94 92 -126 56 -77 230 -288 327 -395 156 -173 446 -450 582 -556 114 -89
312 -237 364 -272 96 -66 293 -193 299 -193 3 0 33 -17 68 -38 91 -56 226
-132 234 -132 3 0 67 -30 142 -66 76 -36 151 -72 167 -79 176 -74 261 -108
290 -115 17 -5 75 -24 130 -43 161 -55 257 -85 302 -92 24 -4 45 -11 49 -16 3
-5 18 -9 34 -9 16 0 41 -5 57 -11 15 -6 57 -17 93 -24 36 -8 97 -21 135 -30
39 -9 115 -23 170 -30 55 -8 129 -19 165 -24 227 -35 608 -51 965 -40 205 6
419 23 475 39 19 5 78 14 130 20 52 6 122 17 155 25 33 8 94 22 135 30 71 15
130 30 245 60 108 28 421 127 430 136 5 5 18 9 29 9 10 0 48 13 83 30 34 16
68 30 73 30 6 0 27 9 47 20 21 11 44 20 51 20 8 0 26 6 40 14 15 8 56 27 92
44 266 124 532 270 688 378 35 24 65 44 68 44 7 0 170 117 303 217 64 49 168
134 275 227 67 58 366 353 441 436 48 53 66 74 187 220 83 101 150 189 193
255 22 33 42 62 45 65 13 13 61 82 105 155 27 44 52 82 55 85 8 8 39 62 63
110 12 25 24 47 27 50 34 31 247 460 316 635 15 39 35 89 46 112 10 23 18 49
18 58 0 9 6 26 14 38 7 12 21 47 31 77 10 30 23 71 30 90 7 19 23 69 35 110
11 41 25 89 30 105 8 26 58 227 91 365 22 92 75 448 99 665 18 160 25 634 11
779 -6 66 -14 153 -17 191 -6 81 -42 352 -50 377 -2 9 -11 56 -19 105 -26 168
-72 366 -111 478 -8 23 -14 48 -14 57 0 18 -103 336 -119 366 -5 9 -28 67 -51
127 -24 61 -56 139 -72 175 -43 94 -225 460 -248 495 -17 28 -36 62 -86 155
-11 19 -22 37 -25 40 -8 7 -149 220 -149 226 0 2 -7 12 -15 22 -9 9 -52 67
-96 127 -45 61 -87 115 -95 121 -8 6 -14 15 -14 20 0 5 -16 27 -36 49 -20 22
-57 65 -82 95 -138 161 -400 425 -572 574 -69 60 -126 113 -128 118 -2 4 -8 8
-13 8 -5 0 -36 21 -69 48 -87 67 -167 127 -230 170 -76 52 -124 86 -130 92 -3
3 -14 10 -25 17 -99 59 -175 109 -175 115 0 5 -8 8 -18 8 -9 0 -33 11 -52 23
-76 52 -456 247 -480 247 -6 0 -21 6 -33 14 -66 41 -557 222 -657 242 -19 3
-39 10 -45 14 -5 4 -30 12 -55 18 -25 5 -90 21 -145 35 -235 59 -525 106 -800
132 -238 22 -255 24 -255 20 0 -2 23 -28 51 -56 115 -117 139 -268 66 -414
-27 -55 -107 -128 -162 -149 l-45 -18 0 -358 0 -358 -42 -17 c-42 -16 -74 -45
-105 -91 -8 -13 -19 -24 -23 -24 -5 0 -10 63 -12 140 -3 125 -5 141 -23 154
-16 13 -52 15 -199 13 l-179 -2 -28 -54 c-38 -73 -82 -116 -155 -152 -141 -70
-287 -42 -401 77 -55 58 -83 134 -83 228 0 84 11 121 56 191 60 95 148 139
279 139 84 0 94 -2 153 -34 73 -40 107 -74 148 -150 l29 -55 198 -3 197 -2 0
180 0 181 -45 19 c-61 26 -141 108 -171 175 -36 83 -35 205 4 283 15 30 48 77
75 103 26 25 47 49 47 53 0 9 -85 6 -305 -14z m1440 -551 c40 -27 75 -90 75
-136 0 -46 -25 -96 -64 -131 -68 -59 -156 -55 -223 12 -45 45 -60 91 -49 151
8 42 58 102 101 122 43 19 117 11 160 -18z m127 -940 c209 -44 328 -254 257
-456 -30 -84 -96 -155 -190 -200 l-64 -32 971 1 c725 1 979 -2 1006 -11 26 -9
39 -21 47 -41 6 -19 8 -208 4 -547 -5 -452 -8 -521 -22 -540 -16 -22 -17 -22
-332 -23 -309 0 -316 0 -335 21 -17 19 -19 43 -24 254 -3 128 -8 236 -12 240
-4 4 -223 9 -487 12 -374 4 -484 3 -496 -7 -13 -11 -15 -80 -15 -501 0 -473 1
-489 19 -499 13 -6 199 -10 536 -10 444 0 520 -2 546 -15 l29 -16 -2 -287 -3
-287 -35 -18 c-33 -17 -67 -17 -530 -12 -278 4 -508 2 -525 -3 l-30 -8 -5
-460 c-5 -406 -8 -465 -24 -514 -27 -85 -62 -153 -90 -179 -14 -14 -26 -30
-26 -36 0 -16 187 -22 746 -25 408 -2 452 0 467 15 15 14 17 47 17 261 0 272
5 300 57 306 67 7 633 -8 642 -17 15 -15 16 -1138 1 -1165 -6 -11 -21 -22 -33
-25 -12 -3 -696 -4 -1519 -3 l-1497 3 -23 23 c-23 23 -23 24 -20 272 1 165 6
261 15 287 12 37 13 38 72 44 33 4 87 7 121 8 86 1 163 43 211 115 41 59 51
97 64 216 12 123 11 815 -2 856 -13 41 -25 44 -186 44 -185 0 -173 -21 -173
307 0 155 4 262 10 274 21 39 59 48 203 46 75 -1 139 2 144 7 8 8 10 317 4
650 -3 186 -16 243 -69 299 -35 37 -83 48 -240 57 -186 10 -176 -4 -180 243
-4 220 4 331 24 351 8 8 14 19 14 25 0 6 7 11 15 11 20 0 24 24 27 161 3 109
5 119 33 165 35 56 95 101 151 114 21 5 107 10 191 10 l151 0 10 30 c31 89
119 168 221 201 67 21 93 23 163 8z m-2224 -36 c-10 -2 -26 -2 -35 0 -10 3 -2
5 17 5 19 0 27 -2 18 -5z m109 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m133 -84 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z m34 -36 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z
m-190 -45 c50 -25 72 -53 87 -111 17 -61 5 -113 -35 -154 -79 -81 -186 -84
-258 -8 -135 144 29 362 206 273z m734 -70 c6 -1 12 -6 12 -10 0 -4 11 -10 25
-13 14 -4 25 -11 25 -16 0 -13 0 -13 -45 10 -36 18 -59 20 -265 20 -124 0
-220 4 -213 8 10 6 424 7 461 1z m-813 -413 c44 0 195 2 335 4 314 5 425 1
467 -16 53 -22 182 -17 167 7 -3 5 6 9 19 9 14 0 27 -7 31 -15 3 -8 14 -15 25
-15 12 0 22 -10 28 -27 11 -34 -4 -509 -17 -540 -12 -28 -57 -40 -176 -46
-100 -5 -138 -20 -192 -71 -44 -40 -59 -71 -83 -163 -17 -70 -19 -113 -19
-524 0 -398 2 -448 16 -453 9 -3 78 -6 155 -6 135 0 141 -1 167 -25 l27 -25 3
-247 c2 -135 1 -257 -3 -270 -3 -13 -14 -32 -25 -43 -18 -18 -34 -20 -157 -20
-76 0 -148 -3 -160 -6 l-23 -6 0 -377 c0 -317 3 -387 17 -446 33 -141 88 -175
291 -183 192 -7 176 22 173 -318 -2 -238 -5 -282 -18 -296 -15 -17 -97 -18
-1452 -18 -790 0 -1453 4 -1473 9 -78 18 -73 -19 -75 576 -3 526 -2 533 18
556 l21 24 296 0 c312 1 350 -4 371 -43 7 -13 11 -102 11 -239 0 -120 4 -224
8 -231 6 -9 54 -14 168 -17 228 -6 251 -7 541 -6 224 1 264 3 277 17 14 14 16
72 16 490 0 309 -4 482 -10 495 -10 18 -27 19 -540 19 l-529 0 -28 24 -28 24
-3 252 c-3 258 1 293 36 322 12 10 129 13 545 13 518 0 530 0 543 20 10 16 14
121 17 475 l4 455 31 80 c18 44 46 99 62 122 17 23 30 47 30 55 0 19 -1183 26
-1206 7 -13 -10 -15 -52 -13 -270 1 -274 2 -271 -47 -297 -26 -14 -597 -6
-624 8 -11 6 -24 19 -30 29 -6 12 -10 225 -10 577 l-1 559 27 24 28 23 945 -8
c520 -5 982 -9 1026 -8z m37 -4616 l23 -18 3 -592 c1 -338 -2 -614 -7 -644 -5
-28 -7 -53 -5 -55 2 -2 38 -6 79 -9 90 -6 95 -11 96 -110 1 -81 -5 -108 -29
-117 -9 -4 -136 -6 -282 -6 -326 2 -315 -2 -315 118 0 92 9 107 72 117 24 5
51 18 64 32 l24 26 3 469 c2 309 -1 483 -8 509 -9 33 -16 41 -48 50 -20 5 -43
10 -50 10 -7 0 -24 11 -37 25 -22 21 -25 33 -25 93 0 114 13 120 252 121 148
0 170 -3 190 -19z m3627 -51 c134 -47 144 -250 16 -314 -45 -22 -118 -24 -156
-5 -113 59 -137 203 -47 287 45 42 121 55 187 32z m-4175 -52 c23 -16 24 -20
28 -184 5 -207 0 -228 -63 -236 -47 -7 -146 -2 -177 7 -26 8 -42 48 -42 101 0
70 -11 75 -159 75 -85 0 -131 -4 -139 -12 -15 -15 -17 -265 -2 -288 7 -12 38
-15 162 -17 84 -1 158 -2 164 -2 5 -1 20 -10 32 -21 19 -18 22 -31 22 -96 0
-87 -18 -133 -53 -135 -12 0 -89 -2 -171 -4 -109 -3 -151 -7 -157 -17 -5 -7
-9 -68 -9 -135 0 -68 -5 -143 -12 -168 -7 -24 -9 -48 -5 -51 4 -4 79 -10 167
-12 119 -3 164 -1 175 8 11 9 15 32 15 79 0 100 7 105 130 109 94 4 103 2 126
-19 24 -22 24 -25 24 -203 0 -171 -1 -181 -22 -203 l-21 -23 -511 0 c-510 0
-510 0 -533 22 -20 19 -23 31 -23 98 0 92 12 112 80 135 75 25 83 42 88 202 5
153 -3 183 -49 183 -15 0 -37 9 -50 19 -22 17 -24 27 -24 110 0 53 5 95 11
101 7 7 30 14 52 17 53 7 62 29 62 147 0 134 -7 149 -80 169 -78 22 -92 43
-91 137 1 94 17 118 79 122 26 2 251 2 499 2 394 -1 456 -4 477 -17z m2106 -2
c72 -15 180 -52 190 -65 3 -4 16 -13 29 -20 140 -73 217 -295 164 -469 -19
-63 -41 -98 -95 -156 -21 -22 -38 -43 -38 -47 0 -3 26 -33 58 -65 87 -87 125
-179 163 -393 10 -57 23 -117 29 -134 5 -16 10 -39 10 -52 0 -35 59 -142 91
-165 62 -44 155 -34 247 27 68 45 103 47 141 8 76 -78 97 -139 63 -180 -29
-34 -136 -103 -204 -131 -55 -23 -78 -26 -183 -27 -97 -1 -130 3 -170 18 -192
75 -272 220 -330 596 -13 84 -45 185 -78 247 -25 48 -62 71 -123 79 -37 4 -65
15 -85 31 -28 24 -29 29 -29 103 0 98 12 126 59 141 117 37 166 104 159 219
-3 59 -8 74 -34 106 -45 56 -89 75 -181 77 l-78 2 -6 -440 c-3 -242 -9 -452
-14 -467 -8 -31 -2 -34 93 -41 75 -6 86 -20 90 -116 3 -62 0 -84 -13 -105
l-17 -26 -293 0 -294 0 -20 26 c-24 31 -28 116 -8 174 10 28 21 37 66 55 94
36 91 17 91 486 0 464 5 436 -90 467 -65 20 -80 43 -80 124 0 60 13 107 33
120 7 4 147 8 312 8 230 0 317 -4 375 -15z m1280 -358 c131 -47 216 -122 278
-246 61 -120 78 -281 48 -436 -35 -184 -173 -355 -322 -399 -33 -9 -75 -22
-95 -27 -41 -13 -144 -7 -209 10 -85 23 -168 71 -229 133 -33 33 -63 70 -67
81 -3 12 -12 29 -18 37 -16 20 -41 100 -57 178 -25 131 5 330 69 442 60 106
185 201 311 236 79 21 216 17 291 -9z m862 -16 c19 -24 19 -39 16 -410 -2
-212 -7 -393 -11 -402 -7 -13 1 -17 45 -22 77 -9 98 -36 98 -128 0 -87 -15
-104 -97 -115 -32 -4 -150 -4 -262 -1 -249 8 -241 4 -241 122 0 93 11 112 74
122 23 4 45 16 61 35 l25 30 0 251 c0 139 -4 258 -8 264 -4 7 -34 22 -66 33
-33 11 -65 25 -72 31 -15 12 -20 142 -8 174 15 37 61 45 247 42 176 -2 179 -2
199 -26z"/>
<path d="M13340 10211 c-25 -7 -25 -9 -28 -124 l-3 -117 288 0 288 1 -53 25
c-71 33 -146 105 -177 169 l-25 53 -132 0 c-73 0 -144 -3 -158 -7z"/>
<path d="M14440 4597 c-85 -54 -114 -123 -115 -272 0 -87 3 -107 26 -155 48
-101 104 -132 213 -116 66 10 124 72 140 151 29 145 6 303 -55 364 -54 55
-148 67 -209 28z"/>
</g>
</svg>


    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}